<template>
  <div class="interpret">
    <div class="interpretBox">
      <div class="mainTitle">
        <h3 class="titleName">{{ $t.mainHeading }}</h3>
        <section class="infoBox">
          <p class="infoName">{{ copyLoadProduct.tProductName }}</p>
          <textarea
            class="titleText"
            :placeholder="$t.pleaseTranslate"
            v-model="loadProduct.TProductName"
            rows="4"
          />
        </section>
      </div>
      <!-- 副标题 -->
      <!-- <div class="mainTitle">
        <h3 class="titleName">{{ $t.subheading }}</h3>
        <section class="infoBox">
          <p class="infoName">{{ copyLoadProduct.tProductSTitle }}</p>
          <textarea
            class="titleText"
            :placeholder="$t.pleaseTranslate"
            v-model="loadProduct.TProductSTitle"
            rows="4"
          />
        </section>
      </div> -->
      <!-- 规格 -->
      <div class="mainTitle">
        <h3 class="titleName">{{ $t.specification }}</h3>
        <section class="infoBox">
          <div
            class="specificationBox"
            v-for="item in loadProduct.ListClassModels"
            :key="item.PCModelId"
          >
            <section class="specificationTitle">
              <p class="specificationTitleName">{{ item.TModelName }}</p>
              <!-- <input
                class="specificationTitleInput"
                v-model="item.TModelName"
                :placeholder="$t.pleaseTranslate"
              /> -->
            </section>
            <section
              class="specificationItem"
              v-for="newItem in item.SonClassModels"
              :key="newItem.PCModelId"
            >
              <p class="specificationItemName">{{ newItem.ModelName }}</p>
              <input
                class="specificationItemInput"
                v-model="newItem.TModelName"
                :placeholder="$t.pleaseTranslate"
              />
            </section>
          </div>
          <!-- <div class="specificationBox">
            <section class="specificationTitle">
              <p class="specificationTitleName">产品颜色</p>
              <input
                class="specificationTitleInput"
                v-model="color"
                placeholder="请输入翻译"
              />
            </section>
            <section class="specificationItem">
              <p class="specificationItemName">黑色</p>
              <input
                class="specificationItemInput"
                v-model="color"
                placeholder="请输入翻译"
              />
            </section>
          </div> -->
        </section>
      </div>
      <!-- 产品描述 -->
      <div class="mainTitle">
        <h3 class="titleName">{{ $t.productDescription }}</h3>
        <section class="infoBox">
          <p class="infoName">{{ copyLoadProduct.tDescriptions }}</p>
          <textarea
            class="titleText"
            :placeholder="$t.pleaseTranslate"
            v-model="loadProduct.TDescriptions"
            rows="4"
          />
        </section>
      </div>

      <div class="loginOutBox" @click="updateTranslateB">
        <div class="loginOut">{{ $t.confirm }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Interpret",
  data() {
    return {
      formData: {
        mainTitle: "",
      },
      loadProduct: {},
      copyLoadProduct: {
        tDescriptions: "",
        tProductSTitle: "",
        tProductName: "",
      },
      productNumber: "",
      color: "",
      shopId: null,
    };
  },
  beforeMount() {
    const { ProductNumber, ShopId } = this.$route.query;
    this.productNumber = ProductNumber;
    this.shopId = ShopId;
    //console.log(ProductNumber + " " + ShopId);
  },
  mounted() {
    // 获取详情数据
    this.getProductInfoByCustomer();
  },
  methods: {
    // 获取商品详情
    getProductInfoByCustomer() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: this.productNumber,
        ShopId: this.shopId,
      };
      this.$api.product
        .loadB_ProductInfoByShop(param)
        .then((res) => {
          const data = res.data;
          console.log(data);
          this.loadProduct = data;
          this.copyLoadProduct.tDescriptions = data.TDescriptions;
          this.copyLoadProduct.tProductSTitle = data.tProductSTitle;
          this.copyLoadProduct.tProductName = data.TProductName;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //翻译
    updateTranslateB() {
      console.log(this.loadProduct);
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let newListTClassModels = [];
      let ListClassModels = this.loadProduct.ListClassModels;
      ListClassModels.forEach((item) => {
        let model = {};
        model["PCModelId"] = item.PCModelId;
        model["TModelName"] = item.TModelName;
        model["Shop"] = this.shop;
        newListTClassModels.push(model);
        item.SonClassModels.forEach((newItem) => {
          let newmodel = {};
          newmodel["PCModelId"] = newItem.PCModelId;
          newmodel["TModelName"] = newItem.TModelName;
          newmodel["Shop"] = this.shop;
          newListTClassModels.push(newmodel);
        });
      });
      console.log(newListTClassModels);
      let param = {
        ProductNumber: this.productNumber,
        TProductName: this.loadProduct.TProductName,
        TProductSTitle: this.loadProduct.TProductSTitle,
        TDescriptions: this.loadProduct.TDescriptions,
        ShopId: this.ShopId,
        ListTClassModels: newListTClassModels,
      };
      this.$api.product
        .editTranslateB_ProductInfo(param)
        .then((res) => {
          //const data = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonMethod.dialogAlert(res.message, () => {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
          });
        })
        .catch((error) => {
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // //跳转页面
    // changeType(component, name) {
    //   this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
    //     isParams: false,
    //     path: component,
    //     name: name,
    //     query: []
    //   });
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicInput {
  color: #333333;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  ::-webkit-input-placeholder {
    color: #a7a7a7;
  }
}
.interpret {
  width: 100%;
  .interpretBox {
    width: 92%;
    margin: 0 auto;
    padding-top: 4px;
    .mainTitle {
      width: 100%;
      .titleName {
        margin: 0;
        padding: 38px 0 24px 0;
        color: #43cd9a;
        font-size: 16px;
        font-weight: bold;
      }
      .infoBox {
        padding: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        background-color: #ffffff;
        @include publicBoxRadio;
        .infoName {
          padding: 6px 0 12px 0;
          margin: 0;
          color: #333333;
          font-size: 14px;
        }
        .titleText {
          padding: 15px;
          border-radius: 10px;
          font-size: 14px;
          @include publicInput;
        }
        .specificationBox {
          margin-top: 20px;
          .specificationTitle {
            .specificationTitleName {
              margin: 0;
              padding: 0;
              color: #333333;
              font-size: 14px;
            }
            .specificationTitleInput {
              padding: 22px 30px;
              margin-top: 22px;
              border-radius: 5px;
              @include publicInput;
            }
          }
          .specificationItem {
            .specificationItemName {
              margin: 0;
              padding: 0;
              padding-top: 30px;
              color: #333333;
              font-size: 14px;
            }
            .specificationItemInput {
              padding: 15px;
              margin-top: 22px;
              border-radius: 5px;
              font-size: 14px;
              @include publicInput;
            }
          }
        }
      }
    }
    .loginOutBox {
      width: $publicWidth;
      margin: 8% auto;
      .loginOut {
        position: relative;
        width: 100%;
        margin: 0 auto;
        border-radius: 3rem;
        padding: 0.8rem 0;
        text-align: center;
        background: linear-gradient(left, #71d283, #01aaa3);
        color: #ffffff;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}
</style>
